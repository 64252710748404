<template>
  <a-form
    :model="eventState"
    name="basic"
    :label-col="{ span: 2 }"
    :wrapper-col="{ span: 22 }"
    autocomplete="off"
    @finish="onFinish"
    @finishFailed="onFinishFailed"
    @submit="onSubmit"
  >

    <a-form-item label="分类" name="event_type">
      <a-select v-model:value="eventState.event_type"
                :options="utils.dom.select_options_from_cache('event_type')"
                :filter-option="utils.dom.select_filterOption"
                placeholder="请选择分类"
                show-search
                style="width: 200px"/>
    </a-form-item>

    <a-form-item v-if="eventState.event_type === utils.dom.choices.event_type.festival" label="&nbsp;" :colon="false"  name="festival_type">
      <a-radio-group v-model:value="eventState.festival_type">
        <a-radio-button v-for="(item, key) in calendar.Options.festival_types" :key="key" :value="item.value">{{item.text}}</a-radio-button>
      </a-radio-group>
    </a-form-item>

    <a-form-item label="标题" name="subject"
      :rules="[{ required: true, message: 'Please input your subject!' }]"
    >
      <a-input v-model:value="eventState.subject" placeholder="Please input your subject"/>
    </a-form-item>

    <a-form-item label="备注" name="content">
      <a-textarea
        v-model:value="eventState.content"
        placeholder="Please input your content"
        :auto-size="{ minRows: 2, maxRows: 5 }"
      />
    </a-form-item>

    <a-form-item label="地点 " name="place">
      <a-textarea
        v-model:value="eventState.place"
        placeholder="Please input place"
        :auto-size="{ minRows: 2, maxRows: 5 }"
      />
    </a-form-item>

        <a-form-item label="时间" :rules="[{ required: true, message: 'Please input time range!' }]">
          <a-space>
              <a-form-item  label="" :colon="false" name="from_time"
              :rules="[{ required: true, message: 'Please input time range!' }]">
                <a-date-picker v-model:value="eventState.from_time" show-time :allowClear="false" />
              </a-form-item>
              <a-form-item>
                到
              </a-form-item>
              <a-form-item  label="" :colon="false" name="to_time"
                            :rules="[{ required: true, message: 'Please input time range!' }, { validator: bigger_than_from,  }]">
                <a-date-picker v-model:value="eventState.to_time" show-time :allowClear="false" />
              </a-form-item>
              <a-form-item>
                <a-switch v-model:checked="isFullDateEvent"  checked-children="全天" un-checked-children="全天"  />
              </a-form-item>
          </a-space>
    </a-form-item>

    <a-form-item label="重复">
      <a-form-item name="repeat_type">
      <a-select v-model:value="eventState.repeat_type"
                :options="utils.dom.select_options_from_cache('repeat_type')"
                :filter-option="utils.dom.select_filterOption"
                placeholder="请选择分类"
                show-search
                style="width: 200px;margin-right: 10px;"/>
        <a-space v-if="eventState.repeat_type!==utils.dom.choices.repeat_type.no_repeat">
        <a-form-item v-if="eventState.repeat_type === utils.dom.choices.repeat_type.week"
                     :rules="[{ validator: select_repeat_weekday_check,  }]"
                     label="" :colon="false" name="repeat_weekday0">
          <a-switch v-model:checked="eventState.repeat_weekday0" checked-children="一" un-checked-children="一"  />
          <a-switch v-model:checked="eventState.repeat_weekday1" checked-children="二" un-checked-children="二"  />
          <a-switch v-model:checked="eventState.repeat_weekday2" checked-children="三" un-checked-children="三"  />
          <a-switch v-model:checked="eventState.repeat_weekday3" checked-children="四" un-checked-children="四"  />
          <a-switch v-model:checked="eventState.repeat_weekday4" checked-children="五" un-checked-children="五"  />
          <a-switch v-model:checked="eventState.repeat_weekday5" checked-children="六" un-checked-children="六"  />
          <a-switch v-model:checked="eventState.repeat_weekday6" checked-children="日" un-checked-children="日"  />
        </a-form-item>


        <a-form-item v-if="eventState.repeat_type > utils.dom.choices.repeat_type.month"
                     :rules="[{ required: true, message: '请输入月' }]"
                     label="" :colon="false" name="repeat_month_in_year" >
          <a-input v-model:value="eventState.repeat_month_in_year" @blur="check_inputs"
                   suffix="月" type="number"  style="width: 80px" />
        </a-form-item>


        <a-form-item v-if="eventState.repeat_type > utils.dom.choices.repeat_type.week"
                     :rules="[{ required: true, message: '请输入日' }]"
                     label="" :colon="false" name="repeat_day_in_month" >
          <a-input v-model:value="eventState.repeat_day_in_month" @blur="check_inputs"
                   suffix="日" type="number"  style="width: 80px" />
        </a-form-item>

        <a-form-item label="" :colon="false" name="repeat_time"
                     :rules="[{ required: true, message: '请输入时间' }]">
          <a-time-picker v-model:value="eventState.repeat_time" :allowClear="false"/>
        </a-form-item>

      </a-space>
      </a-form-item>


    </a-form-item>

    <a-form-item label="&nbsp;" :colon="false">
      <a-row>
        <a-col :span="20">
          <a-space>
            <a-button :disabled="isSaving" type="primary" name="save_close" html-type="submit">
              <template #icon><FormOutlined/></template>
              {{bnSubmitText}} & 关闭
            </a-button>

            <a-button :disabled="isSaving" name="save_edit" html-type="submit">
              <template #icon><EditOutlined/></template>
              {{bnSubmitText}}
            </a-button>

            <a-button @click="onBnClose">
              <template #icon><CloseOutlined /></template>
              关闭
            </a-button>

          </a-space>
        </a-col>
        <a-col :span="4" style="text-align: right;">
          <a-popconfirm  v-if="eventState.id" title="确定删除记录?" @confirm="onDelete">
            <a-button danger>
              <template #icon><DeleteOutlined /></template>删除
            </a-button>
          </a-popconfirm>
        </a-col>
      </a-row>

    </a-form-item>
  </a-form>

  <Report v-if="eventState.id>0" :allow_add="true"
          app="gnokCalendar" model="eventtodos" :report_name="eventState.money_sign===0?'TodoList':'CashFlow'" :show_history="true"
          :parent_keys="{event_id: eventState.id}" :filters="{}" :sorting="{action_time: 1, done: 1}" />


</template>
<script>
import {inject, defineComponent, computed, ref, reactive, onMounted } from 'vue';
import {FormOutlined, EditOutlined, DeleteOutlined, CloseOutlined} from '@ant-design/icons-vue';
import Report from "@/components/Layout/Report";

export default defineComponent({
  components: {
    Report,
    FormOutlined,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
  },

  setup() {
    let app = 'gnokCalendar'
    let model = 'events'

    const calendar = inject('calendar');
    const user = inject('user');
    const utils = inject('utils')
    let moment = inject('moment');
    let message = inject('message');
    const eventState = reactive({
      id:0,
      repeat_type:0,
    });

    let submitBnName = '';
    const isSaving = ref(true);

    const bnSubmitText = computed(() => {
      if (eventState.id){
        return "保存";
      }else{
        return '新建';
      }
    })

    onMounted(() => {
      let event_id = utils.router.query_id;
      if (!event_id){
        utils.dict.sync_for_DOM(eventState, calendar.eventTemplate, moment)
        eventState.user = user.id;
        let from_time = new Date();
        let query_date = utils.router.query('date');
        if (query_date){
          from_time =  new Date(query_date)
          if (!utils.datetime.isValidDate(from_time)){from_time = new Date();}
        }

        from_time.setHours(from_time.getHours()+1);
        from_time.setMinutes(0);
        from_time.setSeconds(0);
        eventState.from_time = moment(from_time)

        let to_time = from_time;
        to_time.setHours(from_time.getHours()+2);
        to_time.setMinutes(0);
        to_time.setSeconds(0);
        eventState.to_time = moment(to_time)

        let repeat_time = new Date();
        repeat_time.setHours(repeat_time.getHours()+1);
        repeat_time.setMinutes(0);
        repeat_time.setSeconds(0);
        eventState.repeat_time = moment(repeat_time)

        isSaving.value = false;
        utils.dom.page_title(document, '新建')
        return;
      }

      utils.api.get_obj(app, model, event_id)
          .then(response=>{
            load_data(response)
          })
    })


    function load_data(response){
      utils.dict.sync_for_DOM(eventState, response.data, moment)
      utils.dom.page_title(document, eventState.subject)
      isSaving.value = false;
      console.log('event load_data', eventState)
    }

    const bigger_than_from = () => {
      if (eventState.to_time.isBefore(eventState.from_time)){
        return Promise.reject('结束时间不能早于开始时间')
      }
      return Promise.resolve()
    };

    const select_repeat_weekday_check = () => {
      if (eventState.repeat_weekday0
          || eventState.repeat_weekday1
          || eventState.repeat_weekday2
          || eventState.repeat_weekday3
          || eventState.repeat_weekday4
          || eventState.repeat_weekday5
          || eventState.repeat_weekday6){
        return Promise.resolve()
      }
      return Promise.reject('至少选择一项')
    };

    const onSubmit = (e) => {
      isSaving.value = true;
      submitBnName = e.submitter.name;
    };

    const onFinish = () => {
      utils.api.save_obj(app, model, eventState)
          .then((response)=>{
            message.success('已保存')
            if (submitBnName === 'save_close'){
              utils.router.go_back();
              return;
            }
            if (eventState.id){
              load_data(response);
            }else{
              utils.router.edit_event(response.data.id)
            }
          })
          .catch(err=>{
            message.error(err);
          })
          .finally(()=>{
            submitBnName = ''
            isSaving.value = false;
          })
    };


    function onDelete() {
      if (eventState.id){
        utils.api.del_obj(app, model, eventState.id)
          .then(()=>{
            message.info('已删除')
            utils.router.go_back();
          })
          .catch(()=>{
            message.error('删除时出错')
          })
      }else {
        utils.router.go_back();
      }
    }

    function onBnClose(){
      utils.router.go_back();
    }

    const onFinishFailed = () => {
      isSaving.value = false;
       // message.error('Failed:' + errorInfo)
    };


    const isFullDateEvent = computed({
      get () {
          if (!eventState.from_time || !eventState.to_time) return false;
          return (eventState.to_time.format('HHmmss') === '235959');
        },
      set(value){
          if (value){
            let to_date = null;
            if (eventState.to_time){
              to_date = eventState.to_time.format('yyyy-MM-DD')
            }else{
              if (eventState.from_time){
              to_date = eventState.from_time.format('yyyy-MM-DD')
              }
            }

            if (!to_date){
              message.error("请输入开始时间")
              return;
            }
            eventState.to_time = moment(to_date + ' 23:59:59');
          }else{
            eventState.to_time=null;
          }
      }
    });



    function check_inputs(){
      if (eventState.repeat_month_in_year<1 || eventState.repeat_month_in_year>12 ){
        eventState.repeat_month_in_year =1
      }

      if (eventState.repeat_day_in_month<1 || eventState.repeat_day_in_month>31 ){
        eventState.repeat_day_in_month =1
      }
    }


    return {
      utils,
      calendar,
      eventState,
      onSubmit,
      onFinish,
      onFinishFailed,
      onDelete,
      onBnClose,
      bigger_than_from,
      select_repeat_weekday_check,
      bnSubmitText,
      isFullDateEvent,
      isSaving,
      check_inputs,
    };
  },


});
</script>

<style lang="less">
.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>